import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import Logo from "../../assets/images/logo7.png";
import styled from 'styled-components';

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin: auto;
    max-width: 1580px;
    width: 95%;
    justify-content: space-between;
`;

const Header = (props) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1199);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        const headerSection = document.querySelector('.header-section-s5');
        if (headerSection) {
            headerSection.classList.toggle('menu-visible', !isMenuOpen);
        }
    };

    const closeMenu = () => {
        setMenuOpen(false);
        const headerSection = document.querySelector('.header-section-s5');
        if (headerSection) {
            headerSection.classList.remove('menu-visible');
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 1199);
        };

        const handleScroll = () => {
            if (!isDesktop) return;

            const navbar = document.getElementById("navbar");
            if (!navbar) return;

            const sticky = navbar.offsetTop;
            if (window.pageYOffset >= sticky) {
                setSticky(true);
            } else {
                setSticky(false);
            }
            if (window.pageYOffset === 0) {
                setSticky(false);
            }
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isDesktop]);

    return (
        <HeaderContainer>
            <div className={`${props.mClass} ${isMenuOpen ? "isOpen" : "isClose"}`} onClick={toggleMenu}>
                <i className={isMenuOpen ? "ti-close" : "ti-align-justify"} id="bar_close"></i>
            </div>

            <div className="left-sidetext">
                <img src={Logo} alt="Full Stack Developer" />
            </div>
            <header className={`${props.hclass} ${isSticky ? "sticky" : ""}`} id="navbar">
                {isSticky && (
                        <div className="left-sidetext sticky-logo">
                            <img src={Logo} alt="Full Stack Developer" />
                        </div>
                    )}
                <div className={`header-section ${isMenuOpen ? "open-mobilemenu" : ""}`}>
                    <div className="menu" id="menu_wrap">
                        <ul>
                            <li><Link activeClass="active" to="home" spy={true} smooth={true} duration={600} offset={-50} onClick={closeMenu}>Home</Link></li>
                            <li><Link activeClass="active" to="about" spy={true} smooth={true} duration={600} offset={-200} onClick={closeMenu}>About me</Link></li>
                            <li><Link activeClass="active" to="experience" spy={true} smooth={true} duration={600} offset={-100} onClick={closeMenu}>Experience</Link></li>
                            <li><Link activeClass="active" to="skills" spy={true} smooth={true} duration={600} offset={-50} onClick={closeMenu}>Skills</Link></li>
                            <li><Link activeClass="active" to="education" spy={true} smooth={true} duration={600} offset={-80} onClick={closeMenu}>Education</Link></li>
                        </ul>
                    </div>
                </div>
            </header>
        </HeaderContainer>
    );
}

export default Header;
