import React from 'react';
import gifImage from '../../assets/images/ai.gif';  // Asegúrate de usar la ruta correcta al GIF

const CircleContent = ({ handleClick, show }) => {
  return (
    <div className="circle-content" onClick={handleClick} style={{ display: show ? '' : 'none' }}>
      <div>
        <svg viewBox="0 0 100 100">
          <defs>
            <path id="circle" d="
              M 50, 50
              m -37, 0
              a 37,37 0 1,1 74,0
              a 37,37 0 1,1 -74,0" />
          </defs>
          <text>
            <textPath xlinkHref="#circle">
              * Click To Ask * Click To Ask
            </textPath>
          </text>
        </svg>
      </div>
      <div className="arrows">
        <img src={gifImage} alt="rotated gif" className="rotated-gif" />
      </div>
    </div>
  );
};

export default CircleContent;
