import React from 'react'


const About = (props) => {



    return (
        <section id="about" className={"" + props.hclass} >
            <div className="wraper">
                <div className="section-top-content">
                    <h2 className="poort-text poort-in-right">About Me</h2>
                    <h3 className="poort-text poort-in-right">Coding is My Passion. Innovation, My Goal.
                    </h3>
                    <h4 className="scroll-text-animation" data-animation="fade_from_bottom">
                        With a strong background in <span>web</span> and <span>mobile development</span>, 
                        I deliver <span>tech solutions</span> that <span>enhance lives</span> and <span>boost businesses</span>. 
                        Focused on <span>user-centered design</span> and <span>problem-solving</span>, 
                        I ensure <span>quality results</span> and <span>customer satisfaction</span>. 
                        Always keen to <span>innovate</span> and explore <span>new technologies</span> like <span>AI</span> to stay ahead in the <span>tech landscape</span>.
                    </h4>
                </div>
            </div>
            <div className="line-shape"></div>
        </section>
    )
}

export default About;