import React from 'react';
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.svg'
import ProgressTracker from '../ProgressTracker/ProgressTracker';

const hero = () => {
    return (
        <section className="hero-section" id="home">

            <div className="hero-wrap">
                <div className="section-top-content">
                    <h2 className="poort-text poort-in-right">Introduction</h2>
                    <h1 className="poort-text poort-in-right">Full Stack Developer</h1>
                    <p>Hi! I'm a <span>Full Stack Developer</span> with over 7 years of experience.</p>
                    <p>I've collaborated with agencies, startups, and talented teams to create <span>digital products</span> that make a difference. 
                         I love tackling <span>new challenges</span>, learning the <span>latest technologies</span>, and finding <span>creative solutions</span>. </p>
                    <p> Based in Sydney, I'm passionate about coding, <span>problem-solving</span>, and always <span>staying curious</span>.</p>
                    <p>Let's build something great together!</p>
                    <div className="social-and-download"> 
                        <ul className="socal-icon s2">
                            <li>
                                <a href="https://www.linkedin.com/in/alanmbenitez/" target="_blank" rel="noopener noreferrer"><i className="ti-linkedin"></i></a>
                            </li>
                            <li>
                                <a href="https://github.com/alanmbenitez" target="_blank" rel="noopener noreferrer"><i className="ti-github"></i></a>
                            </li>
                            <li>
                                <a href="mailto:example@example.com" target="_blank" rel="noopener noreferrer"><i className="ti-email"></i></a>
                            </li>
                            <li>
                                <a href="tel:+61452046795" target="_blank" rel="noopener noreferrer"><i className="ti-mobile"></i></a>
                            </li>
                        </ul>
                        <div>
                           <a href="/Resume_Alan_Benitez_July_2024.pdf" download class="button-64" role="button"><span class="text">Download my Resume</span></a>
                        </div>
                    </div>
                </div>
                <div className="line-shape"></div>
            </div>

        </section>
    );
};

export default hero;