
import sSimg1 from '../images/service-single/s1.jpg'
import sSimg3 from '../images/service-single/s3.jpg'
import sSimg2 from '../images/service-single/s2.jpg'

import sSimg4 from '../images/service-single/s4.jpg'
import sSimg5 from '../images/service-single/s5.jpg'
import sSimg6 from '../images/service-single/s6.jpg'

import icon1 from '../images/service/1.svg'
import icon3 from '../images/service/3.svg'
import icon4 from '../images/service/4.svg'
import icon5 from '../images/service/5.svg'
import icon7 from '../images/service/cloud.svg'
import icon9 from '../images/service/ai2.svg'

const Services = [
   {
      Id: '01',
      sSImg: sSimg1,
      icon: icon1,
      title: 'Web and Mobile Development',
      slug: 'web-and-mobile-development',
      description: 'JavaScript (React, React Native, Node.js, Vue.js, etc.), PHP (Laravel), MySQL, HTML, CSS, GIT'
   },
   {
      Id: '02',
      sSImg: sSimg2,
      icon: icon9,
      title: 'Collaboration',
      slug: 'collaboration',
      description: 'Strong teamwork and problem-solving abilities, adept at using project management tools like JIRA and Trello to coordinate tasks and ensure timely project delivery.'
   },
   {
      Id: '03',
      sSImg: sSimg3,
      icon: icon3,
      title: 'Innovation and AI',
      slug: 'innovation-and-ai',
      description: 'Continuous learning and adoption of new technologies, Integration of artificial intelligence to enhance application capabilities.'
   },
   {
      Id: '04',
      sSImg: sSimg4,
      icon: icon4,
      title: 'Backend Development',
      slug: 'backend-development',
      description: 'Proficient in server-side development using Node.js and PHP (Laravel). Skilled in designing and managing databases with MySQL, ensuring robust and scalable backend architectures.'
   },
   {
      Id: '05',
      sSImg: sSimg5,
      icon: icon5,
      title: 'Frontend Development',
      slug: 'frontend-development',
      description: 'Experienced in building responsive and dynamic user interfaces using React and Vue.js. Strong proficiency in HTML and CSS to create visually appealing and user-friendly designs.'
   },
   {
      Id: '06',
      sSImg: sSimg6,
      icon: icon7,
      title: 'Cloud Services',
      slug: 'cloud-services',
      description: 'Expertise in managing cloud infrastructure with AWS services including EC2 for virtual servers, RDS for managed databases, and S3 for scalable storage solutions. Ensuring high availability and performance of applications.'
   }
];

export default Services;