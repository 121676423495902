import { useState, useEffect } from 'react';

const useAgentData = () => {
  const [agentData, setAgentData] = useState(null);

  useEffect(() => {
    const fetchAgentData = async () => {
      const agentId = 'agt_ofHYw-C_';

      console.log('Agent ID:', agentId);  // Debugging line
      console.log('new');  // Debugging line


      if (!agentId) {
        console.error('Agent ID is not defined');
        return;
      }

      try {
        const response = await fetch(`/api/agents/${agentId}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setAgentData(data);
        console.log('Agent Data:', data);
      } catch (error) {
        console.error('Error fetching agent data:', error);
      }
    };

    fetchAgentData();
  }, []);

  return agentData;
};

export default useAgentData;
