import React from "react";
import Services from "../../data/Services";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const ServiceSection = (props) => {
    const isMobile = window.innerWidth <= 755; // Detecta si el ancho de la pantalla es menor o igual a 755px

    const responsive = {
        mobile: {
            breakpoint: { max: 755, min: 0 },
            items: 1
        }
    };

    return (
        <section className={props.hclass} id="Skills">
            <div className="wraper">
                <div className="section-top-content">
                    <h2 className="poort-text poort-in-right">Skills</h2>
                    <h3 className="poort-text poort-in-right">Technical Skills</h3>
                </div>
                <div className="row">
                    {isMobile ? (
                        <Carousel
                            responsive={responsive}
                            showDots={true}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            containerClass="carousel-container"
                            itemClass="carousel-item-padding-40-px"
                        >
                            {Services.slice(0, 6).map((service, sitem) => (
                                <div key={sitem} className="service-card-wrapper">
                                    <div className="service-card scroll-text-animation" data-animation="fade_from_bottom">
                                        <div className="icon">
                                            <img src={service.icon} alt={service.title} />
                                        </div>
                                        <div className="content">
                                            <h2>{service.title}</h2>
                                            <span>{service.description}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    ) : (
                        Services.slice(0, 6).map((service, sitem) => (
                            <div className="col col-lg-6 col-md-6 col-12" key={sitem}>
                                <div className="service-card scroll-text-animation" data-animation="fade_from_bottom">
                                    <div className="icon">
                                        <img src={service.icon} alt={service.title} />
                                    </div>
                                    <div className="content">
                                        <h2>{service.title}</h2>
                                        <span>{service.description}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            <div className="line-shape"></div>
        </section>
    );
}

export default ServiceSection;
