import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styled from 'styled-components';
import AICharacter from '../Sections/AICharacter';
import CircleContent from '../Common/CircleContent';
import ChatBox from '../Common/ChatContainer';
import useAgentData from '../../hooks/useAgentData';
import { fetchOpenAIResponse, createTalk, checkVideoStatus } from '../../services/api';
import mine from '../../assets/videos/mine.mp4';
import aboutmeVideo from '../../assets/videos/aboutme.mp4';
import experienceVideo from '../../assets/videos/experience.mp4';
import fromVideo from '../../assets/videos/from.mp4';

gsap.registerPlugin(ScrollTrigger);

const ProfileSectionDiv = styled.div`
  position: relative;
`;

const Content = styled.div`
  text-align: left;
  left: -25px;
  border-radius: 17px;
  z-index: 1500;
  padding: 20px 39px;
  background: #8f65da;
  position: absolute;
  bottom: 20px;
  width: 100%;
`;

const ProfileSection = () => {
  const backgroundVideoRef = useRef(null);
  const videoRef = useRef(null);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [userQuestion, setUserQuestion] = useState("");
  const [questionCount, setQuestionCount] = useState(0);
  const [videoUrl, setVideoUrl] = useState(null);
  const [responseText, setResponseText] = useState(null); 
  const [showVideo, setShowVideo] = useState(false);
  const [showBackgroundVideo, setShowBackgroundVideo] = useState(true);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const agentData = useAgentData();

  const predefinedQuestions = [
    {
      video: experienceVideo,
      question: "Can you tell me about your work experience?",
      answer: "I have over 5 years of experience as a Full Stack Developer. My expertise includes JavaScript (React, React Native, Node.js, Vue.js), PHP (Laravel), MySQL, HTML, CSS, and GIT. I've developed and refined web applications, integrated APIs, and managed cloud services."
    },
    {
      video: fromVideo,
      question: "Where are you from?",
      answer: "I was originally born and raised in Buenos Aires, Argentina. However, I currently reside in Sydney, Australia."
    },
    {
      video: aboutmeVideo,
      question: "Share something about yourself.",
      answer: "Outside of my professional life, I have a passion for staying active. I regularly visit the gym and participate in outdoor activities. I also set personal goals for continuous learning, particularly in emerging tech trends. I am quite detail-oriented, and one of my aspirations is to blend efficiency with perfection in my work."
    }
  ];

  const handleThumbnailClick = () => {
    setShowThumbnail(false);
    if (backgroundVideoRef.current) {
      backgroundVideoRef.current.src = mine;
      backgroundVideoRef.current.loop = true;
      backgroundVideoRef.current.load();
      backgroundVideoRef.current.play().catch(error => {
        console.error('Error playing background video:', error);
      });
    }
  };

  const handleInputChange = (event) => {
    setUserQuestion(event.target.value);
  };

  const handleSubmitQuestion = async () => {
    if (userQuestion && questionCount < 3) {
      const newMessage = { text: userQuestion, isUser: true };
      setMessages([...messages, newMessage]);
      setIsLoading(true);
      setUserQuestion("");
      setQuestionCount(prevCount => prevCount + 1);
      try {
        const responseText = await fetchOpenAIResponse(userQuestion);
        setResponseText(responseText);
        console.log(responseText);
        const talkData = await createTalk(agentData, responseText);
        console.log(talkData);
        if (talkData && talkData.id) {
          const interval = setInterval(async () => {
            const videoData = await checkVideoStatus(talkData.id);
            console.log(videoData);
            console.log(videoData.status);
            console.log(videoData.result_url);
console.log(videoData.status, 'videoData.status');
console.log(videoData.result_url, 'videoData.result_url');

            if (videoData.status === 'done' && videoData.result_url) { 
              console.log(videoData.result_url);
              setVideoUrl(videoData.result_url);
              setShowVideo(true);
              setMessages(prevMessages => [...prevMessages, { text: responseText, isUser: false }]);
              setIsLoading(false);
              clearInterval(interval);
            }
          }, 5000);
        }
      } catch (error) {
        console.error('Error handling question:', error);
        setIsLoading(false);
      }
    }
  };

  const handleFloatingButtonClick = (question, answer) => {
    setMessages(prevMessages => [
      ...prevMessages,
      { text: question, isUser: true },
      { text: answer, isUser: false }
    ]);
    setShowInput(true);
  };

  return (
    <ProfileSectionDiv>
      <div className="profile-s2">
        <AICharacter
          backgroundVideoRef={backgroundVideoRef}
          showThumbnail={showThumbnail}
          setShowThumbnail={setShowThumbnail}
          setShowInput={setShowInput}
          handleFloatingButtonClick={handleFloatingButtonClick}
          predefinedQuestions={predefinedQuestions}
          videoRef={videoRef}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          showVideo={showVideo}
          setShowVideo={setShowVideo}
        />
        {showThumbnail ? (
          <Content className="content">
            <h2 className="poort-text poort-in-right">Hi, I’m</h2>
            <h3 className="poort-text poort-in-right">Alan Benitez</h3>
            <p> Would you like to know more about me? </p>
          </Content>
        ) : (
          <ChatBox
            show={!showThumbnail && showInput}
            userQuestion={userQuestion}
            handleInputChange={handleInputChange}
            handleSubmitQuestion={handleSubmitQuestion}
            questionCount={questionCount}
            messages={messages}
            isLoading={isLoading}
          />
        )}
      </div>
      <CircleContent handleClick={handleThumbnailClick} show={showThumbnail} />
    </ProfileSectionDiv>
  );
};

export default ProfileSection;
