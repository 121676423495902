import styled from 'styled-components';

const QuestionsContainer = styled.div`
    width: 100%;
    z-index: 2300;
    height: 100%;
    display: ${props => (props.show ? 'block' : 'none')};
`;

export default QuestionsContainer;
