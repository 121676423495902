import styled from 'styled-components';

const AgentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  color: #fff;

  @media (max-width: 575px) {
    max-width: 95%;
  }

`;

export default AgentContainer;
