const Education = [
    {
        id: '1',
        title: 'Diploma of Information Technology',
        date: 'Mar 2024 - Jul 2025',
        location: 'Academy of Interactive Technology, Sydney - Australia',
        details: [
            'Focus on web development and software engineering.',
            'Courses include advanced JavaScript, PHP, and cloud computing.'
        ]
    },
    {
        id: '2',
        title: 'Applications Development with React Native',
        date: 'Jul 2021 - Sept 2021',
        location: 'CoderHouse, Buenos Aires, Argentina',
        details: [
            'Developed mobile applications using React Native.',
            'Gained proficiency in cross-platform mobile development.'
        ]
    },
    {
        id: '3',
        title: 'Backend Development - MERN',
        date: 'Jul 2021 - Dec 2021',
        location: 'CoderHouse, Buenos Aires, Argentina',
        details: [
            'Focused on backend development using MongoDB, Express, React, and Node.js.',
            'Learned to create and manage RESTful APIs.'
        ]
    },
    {
        id: '4',
        title: 'Mobile Android Development',
        date: 'Mar 2019 - Aug 2019',
        location: 'Digital House, Buenos Aires, Argentina',
        details: [
            'Developed Android applications with Java and Kotlin.',
            'Worked on projects involving UI/UX design and database integration.'
        ]
    },
    {
        id: '5',
        title: 'Web Full Stack Development',
        date: 'Jul 2018 - Dec 2018',
        location: 'Digital House, Buenos Aires, Argentina',
        details: [
            'Developed and deployed web applications using React and Node.js.',
            'Designed and implemented a new database schema using MySQL.'
        ]
    },
    {
        id: '6',
        title: 'Google AI Essentials',
        date: 'Jun 2024',
        location: 'Google',
        details: [
            'Learned fundamental concepts of artificial intelligence.',
            'Completed projects involving machine learning algorithms and data analysis.'
        ]
    }
];

export default Education;
