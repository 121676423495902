import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const ChatContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 17px;
  z-index: 1500;
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
`;

const ChatBoxContent = styled.div`
  width: 95%;
  max-height: 150px; /* Ajusta este valor según sea necesario */
  overflow-y: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Message = styled.div`
  background: ${props => (props.isUser ? 'linear-gradient(120deg, #5937ac, #651f74)' : 'linear-gradient(120deg, #248A52, #257287);')};
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
  align-self: ${props => (props.isUser ? 'flex-end' : 'flex-start')};
  max-width: 80%;
  white-space: pre-wrap;
`;

const InputContainer = styled.div`
  display: flex;
  width: 95%;
  justify-content: center;
  align-items: center;
  gap: 10px;

  input {
    width: 80%;
    padding: 10px;
    border-radius: 5px;
    border: none;
  }

  button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #8f65da;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ChatBox = ({ show, userQuestion, handleInputChange, handleSubmitQuestion, questionCount, messages, isLoading }) => {
  const chatBoxRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, isLoading]);

  useEffect(() => {
    if (inputRef.current) {
      //inputRef.current.focus();
    }
  }, [inputRef, show]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmitQuestion();
    }
  };

  return (
    <ChatContainer show={show}>
      <ChatBoxContent ref={chatBoxRef}>
        {messages.map((msg, index) => (
          <Message key={index} isUser={msg.isUser}>
            {msg.text}
          </Message>
        ))}
        {isLoading && <Message isUser={false}>...</Message>}
      </ChatBoxContent>
      {questionCount >= 3 && !isLoading ? (
        <p>If you have more questions, just email me at contact@alanbenitezdev.com.au.</p>
      ) : (
        <InputContainer>
          <input
            type="text"
            value={userQuestion}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Or type your question here..."
            ref={inputRef}
          />
          <button onClick={handleSubmitQuestion}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </InputContainer>
      )}
    </ChatContainer>
  );
};

export default ChatBox;
