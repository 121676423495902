import styled from 'styled-components';

const Video = styled.video.withConfig({
  shouldForwardProp: (prop) => !['isBackground'].includes(prop)
})`
  width: 100%;
  border-radius: 10px;
  position: absolute;
  z-index: ${props => (props.isBackground ? 999 : 1000)};
  display: ${props => (props.show ? 'block' : 'none')};

  &::-internal-media-controls-overlay-cast-button {
    display: none;
  }
`;

export default Video;
