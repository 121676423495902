const Resume = [
    {
        id: '1',
        title: 'Full Stack Developer',
        date: '2023 - Present',
        location: 'I’m in the Right, Sydney, Australia',
        details: [
            'Developed and refined web applications using PHP, Laravel, MySQL, HTML, CSS, and jQuery.',
            'Maintained high-quality standards through meticulous attention to detail in code structuring, debugging, and database design.',
            'Integrated third-party APIs to enhance application functionality and user experience.'
        ]
    },
    {
        id: '2',
        title: 'Full Stack Developer',
        date: '2022 - 2023',
        location: 'Wiseberry Real Estate, Sydney, Australia',
        details: [
            'Collaborated with cross-functional teams to design and develop new features and enhancements for web applications using React and React Native.',
            'Developed and maintained mobile applications using React Native.',
            'Managed AWS cloud services (EC2, RDS, S3) to optimize application performance and scalability.',
            'Conducted code reviews, identified and fixed bugs, and maintained documentation for applications.'
        ]
    },
    {
        id: '3',
        title: 'Full Stack Developer',
        date: '2018 - 2022',
        location: 'Digital House, Buenos Aires, Argentina',
        details: [
            'Developed and deployed web applications using PHP, Laravel, Vue, Node.js, and React.',
            'Designed and implemented new database schemas using MySQL.',
            'Collaborated with cross-functional teams to develop and deploy new features and enhancements for web applications.'
        ]
    },
    {
        id: '4',
        title: 'Web Developer',
        date: '2017 - 2018',
        location: 'Freelance, Various Companies',
        details: [
            'Designed and developed various websites for different companies using PHP and Laravel.',
            'Created custom web solutions with a focus on user experience and design.',
            'Worked closely with clients to ensure their satisfaction and meet project requirements.'
        ]
    }
];

export default Resume;
