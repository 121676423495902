import config from '../config';

export const fetchOpenAIResponse = async (userMessage) => {
  const response = await fetch('/api/openai', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userMessage }),
  });

  if (!response.ok) {
    throw new Error(`OpenAI API request failed with status ${response.status}`);
  }
  const data = await response.json();
  return data;
};


export const createTalk = async (agentData, responseText) => {
  const response = await fetch('/api/talks', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ agentData, responseText }),
  });

  if (!response.ok) {
    throw new Error(`D-ID API request failed with status ${response.status}`);
  }
  const talkData = await response.json();
  return talkData;
};


export const checkVideoStatus = async (talkId) => {
  try {
    const response = await fetch(`/api/talks/${talkId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });
console.log(response, response);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const videoData = await response.json();
    return videoData;
  } catch (error) {
    console.error('Error checking video status:', error);
    throw error;
  }
};

