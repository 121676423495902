import React from 'react'

const ResumeSection = ({id, hclass, badge, title, data }) => {

    return (
        <section className={"" + hclass} id={id}>
        <div className="wraper">
            <div className="section-top-content">
                <h2 className="poort-text poort-in-right">{badge}</h2>
                <h3 className="poort-text poort-in-right">{title}</h3>
            </div>
            <div className="resume-content">
                {data.map((item, Rky) => (
                    <div className="item scroll-text-animation" data-animation="fade_from_right" key={Rky}>
                        <span>{item.date}</span>
                        <h2>{item.title}</h2>
                        <p><span>{item.location}</span></p>
                        <ul>
                            {item.details.map((detail, index) => (
                                <li key={index}>{detail}</li>
                            ))}
                        </ul>
                        <div className="line-shape-2"></div>
                    </div>
                ))}
            </div>
        </div>
        <div className="line-shape"></div>
    </section>
    )
}

export default ResumeSection;