import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import './App.css';

const App = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = './lavaLamp.js'; // Asegúrate de que el archivo JS esté en la carpeta public
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="App" id='scrool'>
      <div className="wrap">
          <canvas id="bubble"></canvas>
        
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

