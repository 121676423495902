import React from 'react';
import styled, { keyframes } from 'styled-components';

const floatAnimation = keyframes`
  0% { transform: translate(0, 0); }
  50% { transform: translate(10px, -10px); }
  100% { transform: translate(0, 0); }
`;

const SvgButton = styled.img`
  position: absolute;
  width: 160px;  /* Ajusta el tamaño según sea necesario */
  cursor: pointer;
  animation: ${floatAnimation} 3s ease-in-out infinite;

  @media (max-width: 576px) {
    width: 90px;
    ${props => props.rightMobile !== undefined && `right: ${props.rightMobile}px;`}
    ${props => props.leftMobile !== undefined && `left: ${props.leftMobile}px;`}
  }

  ${props => props.right !== undefined && `right: ${props.right}px;`}
  ${props => props.left !== undefined && `left: ${props.left}px;`}
`;

const FloatingButton = ({ src, top, left, right, bottom, leftMobile, rightMobile, onClick }) => {
  return <SvgButton src={src} style={{ top, bottom }} right={right} left={left} rightMobile={rightMobile} leftMobile={leftMobile} onClick={onClick} />;
};

export default FloatingButton;
