import styled from 'styled-components';

const Thumbnail = styled.img`
  max-width: 500px;
  width: 100%;
  /* height: 150px; */
  /* border-radius: 50%; */
  border-radius: 17px;
  object-fit: cover;
  z-index: 1000;
  cursor: pointer;
  display: ${props => (props.show ? 'block' : 'none')};
`;

export default Thumbnail;
