import React, { useEffect, useState } from 'react';
import useAgentData from '../../hooks/useAgentData';
import AgentContainer from './AgentContainer';
import Thumbnail from '../Common/Thumbnail';
import VideoContainer from '../Common/VideoContainer';
import Video from '../Common/Video';
import QuestionsContainer from '../Common/QuestionsContainer';
import FloatingButton from '../Common/FloatingButton';

import Svg1 from '../../assets/images/1.svg';
import Svg2 from '../../assets/images/2.svg';
import Svg3 from '../../assets/images/3.svg';
import Agent from '../../assets/images/agent.jpeg';


const AICharacter = ({ backgroundVideoRef, showThumbnail, setShowThumbnail, setShowInput, handleFloatingButtonClick, predefinedQuestions, videoRef, videoUrl, setVideoUrl, showVideo, setShowVideo }) => {
  const agentData = useAgentData();

  const handleVideoPlay = (url) => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.removeAttribute('src');
      videoRef.current.load();

      setTimeout(() => {
        videoRef.current.src = url;
        videoRef.current.loop = false;

        const onCanPlayThrough = () => {
          videoRef.current.play().catch(error => {
            console.error('Error playing video:', error);
          });
          setShowVideo(true);
          if (backgroundVideoRef.current) {
            backgroundVideoRef.current.pause();
          }
        };

        videoRef.current.addEventListener('canplaythrough', onCanPlayThrough, { once: true });

        videoRef.current.addEventListener('ended', () => {
          setShowVideo(false);
          setVideoUrl(null);
          if (backgroundVideoRef.current) {
            backgroundVideoRef.current.play().catch(error => {
              console.error('Error playing background video after foreground video ended:', error);
            });
          }
        }, { once: true });

        videoRef.current.load();
      }, 100);
    }
  };

  const handleQuestionClick = (index) => {
    const selected = predefinedQuestions[index];
    setVideoUrl(selected.video);
    setShowInput(true);
    handleFloatingButtonClick(selected.question, selected.answer);
    handleVideoPlay(selected.video);
  };

  useEffect(() => {
    if (videoUrl) {
      handleVideoPlay(videoUrl);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (!videoUrl && backgroundVideoRef.current) {
      backgroundVideoRef.current.play().catch(error => {
        console.error('Error playing background video:', error);
      });
    }
  }, [videoUrl]);

  const preventUserControl = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
console.log(showVideo, 'showvideo');
console.log(backgroundVideoRef, 'backgroundVideoRef');
console.log(videoRef, 'videoRef');

  return (
    <AgentContainer>
      {Agent  && (
        <>
          <Thumbnail
            src={Agent}
            alt="Agent profile Thumbnail"
            onClick={() => setShowThumbnail(false)}
            show={showThumbnail}
          />
          <VideoContainer>
            <Video 
              ref={backgroundVideoRef} 
              controls={false} 
              loop={true} 
              show={!showVideo} 
              isBackground={true} 
              playsInline 
              webkit-playsinline="true"
              onClick={preventUserControl}
              style={{ zIndex: showVideo ? 999 : 1000 }}
            />
            <Video 
              ref={videoRef} 
              controls={false} 
              loop={false} 
              show={showVideo} 
              isBackground={false} 
              playsInline 
              webkit-playsinline="true"
              onClick={preventUserControl}
              style={{ zIndex: showVideo ? 1000 : 999 }}
            />
          </VideoContainer>
          <QuestionsContainer show={!showThumbnail}>
            <FloatingButton src={Svg1} top="10%" right={427} rightMobile={320} onClick={() => handleQuestionClick(0)} />
            <FloatingButton src={Svg2} top="30%" left={423} leftMobile={317} onClick={() => handleQuestionClick(1)} />
            <FloatingButton src={Svg3} top="50%" right={433} rightMobile={324} onClick={() => handleQuestionClick(2)} />
          </QuestionsContainer>
        </>
      )}
    </AgentContainer>
  );
};

export default AICharacter;
