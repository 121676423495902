import React, { Fragment, useLayoutEffect, useEffect } from 'react';
import ProfileSection from '../ProfileSection/ProfileSection';
import Hero from '../hero/hero';
import Header from '../header/header';
import About from '../about/about';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.svg'
import ResumeSection from '../ResumeSection/ResumeSection';
import ResumeData from '../../data/Resume';
import Education from '../../data/Education';
import Skills from '../SkilsSection/SkillsSection';
import Testimonial from '../Testimonial/Testimonial';
import ContactForm from '../ContactSection/ContactSection';
import BlogSectionS2 from '../BlogSectionS2/BlogSectionS2';
import { Analytics } from "@vercel/analytics/react"
import gsap from 'gsap';

gsap.registerPlugin(ScrollTrigger);

const HomePage = () => {

    // useEffect(() => {
    //     function scroll_animations() {
    //         console.log('test');
    //         const defaults = {
    //           duration: 1.2,
    //           ease: "power4.out",
    //           animation: "fade_from_bottom",
    //           once: false, // Cambiado a false para permitir múltiples reproducciones
    //         };
        
    //         gsap.utils.toArray(".scroll-text-animation").forEach((box) => {
    //           const gsap_obj = {};
    //           const settings = {
    //             duration: box.dataset.animationDuration || defaults.duration,
    //           };
    //           const animations = {
    //             fade_from_bottom: {
    //               y: 180,
    //               opacity: 0,
    //             },
    //             fade_from_top: {
    //               y: -180,
    //               opacity: 0,
    //             },
    //             fade_from_left: {
    //               x: -180,
    //               opacity: 0,
    //             },
    //             fade_from_right: {
    //               x: 180,
    //               opacity: 0,
    //             },
    //             fade_in: {
    //               opacity: 0,
    //             },
    //             rotate_up: {
    //               y: 180,
    //               rotation: 10,
    //               opacity: 0,
    //             },
    //           };
    //           const scroll_trigger = {
    //             scrollTrigger: {
    //               trigger: box,
    //               once: defaults.once, // Cambiado a false para permitir múltiples reproducciones
    //               start: "top 80%", // Ajusta este valor según sea necesario
    //               toggleActions: "play none none none", // Cambiado para evitar el reverse
                  
    //             },
    //           };
        
    //           console.log(scroll_trigger);
        
    //           Object.assign(gsap_obj, settings);
    //           Object.assign(gsap_obj, animations[box.dataset.animation || defaults.animation]);
    //           Object.assign(gsap_obj, scroll_trigger);
    //           gsap.from(box, gsap_obj);
    //         });
    //       }
        
    //       scroll_animations();
    //   }, []);


    return (
        <Fragment>
            <div className="page-wrapper">
                <Header mClass={"menu-open menu-open-s2"} hclass={"header-section-s5"} />
                <div className="left-wraper-s2">
                    <ProfileSection/>
                </div>
                <div className="right-wraper s2">
                    <Hero />
                    <About hclass={'about-section s2'} />
                    <ResumeSection id="experience" hclass={"resume-section"} badge={"My Experience"} title={"My Professional Journey"} data={ResumeData}/>
                    <Skills hclass={"service-section"} />
                    <ResumeSection id="education" hclass={"resume-section"} badge={"Education"} title={"Continuous Learning & Professional Growth"} data={Education}/>
                    {/* <ProjectSection hclass={"portfolio-section"} /> */}
                    {/* <Testimonial hclass={'testimonial-section'} /> */}
                    {/* <BlogSectionS2 /> */}
                    {/* <ContactForm Cclass={"contact-section"} /> */}
                </div>
            </div>
            <Analytics />
        </Fragment>
    )
};
export default HomePage;